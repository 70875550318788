<nav
    class="h-16 flex items-center justify-between"
    [ngClass]="{
        '!bg-blue-7 max-w-5xl mt-6 mx-auto': !isAppRoute,
        'bg-black-3': router.url !== '/projects' && router.url !== '/'
    }"
>
    <div class="flex items-center gap-5 ml-5">
        <a href="{{ environmentName === 'eveara' ? '/' : wpUrl }}">
            <img
                [alt]="appName + ' logo'"
                [src]="
                    environmentName === 'eveara'
                        ? '/assets/images/eveara-logo.png'
                        : '/assets/images/logo.svg'
                "
                class="w-40"
            />
        </a>
        <a
            *ngIf="appStoreService.shareToken === '3f2377421f'"
            href="https://musicteam.com"
        >
            <img
                [alt]="'MusicTeam logo'"
                [src]="'/assets/images/musicteam-logo-color.png'"
                class="w-48"
            />
        </a>
    </div>
    <div *ngIf="!appStoreService.user && !isAppRoute" class="flex gap-5">
        <a
            href="register"
            class="bg-yellow-1 text-blue-7 px-2 py-0.5 font-medium border-2 border-yellow-1 hover:bg-blue-7 hover:text-yellow-1"
        >
            Sign up
        </a>
        <a
            href="login"
            class="bg-yellow-1 text-blue-7 px-2 py-0.5 font-medium border-2 border-yellow-1 hover:bg-blue-7 hover:text-yellow-1"
        >
            Log in
        </a>
    </div>
    <div
        *ngIf="!appStoreService.shareToken && appStoreService.user"
        class="flex"
    >
        <div
            *ngIf="router.url?.includes('/projects/' + projectId)"
            class="flex"
        >
            <div
                class="flex border-r-2 border-black-1"
                *ngIf="router.url !== '/projects'"
            >
                <a
                    routerLink="/projects"
                    class="flex flex-col items-center justify-between w-16 py-2"
                >
                    <fa-icon [icon]="faListUl" class="text-xl"></fa-icon>
                    <span class="text-xs font-medium">Projects</span>
                </a>
            </div>
            <div class="flex space-x-2 border-r-2 border-black-1">
                <a
                    routerLink="/projects/{{ projectId }}"
                    class="flex flex-col items-center justify-between w-16 py-2"
                    [ngClass]="{
                        'text-white border-b-2 border-blue': isProjectRoute('')
                    }"
                >
                    <fa-icon [icon]="faBars" class="text-xl"></fa-icon>
                    <span class="text-xs font-medium">Tracks</span>
                </a>
                <a
                    routerLink="/projects/{{ projectId }}/files"
                    class="flex flex-col items-center justify-between w-16 py-2"
                    [ngClass]="{
                        'text-white border-b-2 border-blue':
                            isProjectRoute('/files')
                    }"
                >
                    <fa-icon [icon]="faFile" class="text-xl"></fa-icon>
                    <span class="text-xs font-medium">Files</span>
                </a>
                <a
                    routerLink="/projects/{{ projectId }}/notes"
                    class="flex flex-col items-center justify-between w-16 py-2"
                    [ngClass]="{
                        'text-white border-b-2 border-blue':
                            isProjectRoute('/notes')
                    }"
                >
                    <fa-icon [icon]="faStickyNote" class="text-xl"></fa-icon>
                    <span class="text-xs font-medium">Notes</span>
                </a>
                <a
                    *ngIf="environmentName !== 'eveara'"
                    routerLink="/projects/{{ projectId }}/musicteam"
                    class="flex flex-col items-center justify-between w-16 py-2"
                    [ngClass]="{
                        'text-white border-b-2 border-blue':
                            isProjectRoute('/musicteam')
                    }"
                >
                    <div class="py-1">
                        <img
                            [src]="
                                '/assets/images/musicteam-logo-' +
                                (isProjectRoute('/musicteam')
                                    ? 'white'
                                    : 'grey') +
                                '.svg'
                            "
                            width="20"
                            alt=""
                        />
                    </div>
                    <span class="text-xs font-medium">MusicTeam</span>
                </a>
                <a
                    routerLink="/projects/{{ projectId }}/settings"
                    class="flex flex-col items-center justify-between w-16 py-2"
                    [ngClass]="{
                        'text-white border-b-2 border-blue':
                            isProjectRoute('/settings')
                    }"
                >
                    <fa-icon [icon]="faCog" class="text-xl"></fa-icon>
                    <span class="text-xs font-medium">Settings</span>
                </a>
            </div>
        </div>
        <div class="flex">
            <a
                *ngIf="!appStoreService.user"
                href="/register"
                class="flex flex-col justify-end items-center w-16 py-2"
            >
                <span class="text-xs font-medium">Sign up</span>
            </a>
            <a
                *ngIf="!appStoreService.user"
                href="/login"
                class="flex flex-col justify-end items-center w-16 py-2"
            >
                <span class="text-xs font-medium">Login</span>
            </a>
            <a
                *ngIf="environmentName !== 'eveara'"
                href="{{ wpUrl }}/support"
                target="_blank"
                class="flex flex-col items-center justify-between w-16 py-2"
            >
                <fa-icon [icon]="faQuestion" class="text-xl"></fa-icon>
                <span class="text-xs font-medium">Help</span>
            </a>
            <div class="relative">
                <div class="w-16 h-16">
                    <img
                        *ngIf="appStoreService.user"
                        src="{{
                            appStoreService.user.avatarUrl ||
                                '/assets/images/default-avatar.webp'
                        }}"
                        alt="Avatar"
                        class="object-cover w-full h-full cursor-pointer"
                        (click)="onClickAvatar()"
                    />
                </div>
                <st-dropdown
                    *ngIf="!appStoreService.shareToken && isShowingMenu"
                >
                    <li stDropdownItem>
                        <a href="/projects">Projects</a>
                    </li>
                    <li *ngIf="environmentName !== 'eveara'" stDropdownItem>
                        <a href="upgrade">Upgrade</a>
                    </li>
                    <li *ngIf="environmentName !== 'eveara'" stDropdownItem>
                        <a (click)="openBillingPortal()">Billing</a>
                    </li>
                    <li stDropdownItem>
                        <a href="account"> Account </a>
                    </li>
                    <li stDropdownItem (click)="logout($event)">
                        <a href=""> Logout </a>
                    </li>
                </st-dropdown>
            </div>
        </div>
    </div>
</nav>
