import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
import {
    ApplicationConfig,
    ErrorHandler,
    inject,
    provideAppInitializer
} from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { routes } from './app.routes';
import { getErrorHandler, getTraceService } from './helpers/error-tracking';
import { CustomEventManager } from './services/custom-event-manager.service';
import { CustomHttpInterceptor } from './services/custom-http-interceptor.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        {
            provide: EventManager,
            useClass: CustomEventManager
        },
        {
            provide: ErrorHandler,
            useValue: getErrorHandler()
        },
        {
            provide: getTraceService(),
            deps: [Router]
        },
        provideAppInitializer(() => {
            inject(getTraceService());
        })
    ]
};
